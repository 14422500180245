// import { IconArrowLeft, IconArrowRight } from "@tabler/icons-react";
// import { useRef } from "react";
// import SendRequestCard from "../sendRequests/SendRequestCard";
// import { useTranslation } from "react-i18next";

// // Reusable Section component for each request type
// const SendRequestSection: React.FC<{
//   title: string;
//   requests: any[];
//   status: "pending" | "accepted" | "rejected";
// }> = ({ title, requests, status }) => {
//   // Ref to the scrollable container
//   const scrollRef = useRef<HTMLDivElement>(null);
//   console.log("Requests data:", requests);

//   // Scroll function
//   const scroll = (direction: "left" | "right") => {
//     if (scrollRef.current) {
//       const { scrollLeft, clientWidth } = scrollRef.current;
//       const scrollAmount = clientWidth / 2; // Adjust scroll amount
//       const newPosition =
//         direction === "left"
//           ? scrollLeft - scrollAmount
//           : scrollLeft + scrollAmount;
//       scrollRef.current.scrollTo({ left: newPosition, behavior: "smooth" });
//     }
//   };

//   const { t } = useTranslation();

//   return (
//     <div className="my-8 w-full relative">
//       <h2 className="text-[16px] font-medium text-custom_black mb-4">
//         {title}
//       </h2>

//       {/* Left Arrow */}
//       <button
//         className="absolute left-0 top-full transform -translate-y-1/2 p-1 mt-4 bg-primary_green rounded-full hidden lg:block"
//         onClick={() => scroll("left")}
//       >
//         <IconArrowLeft size={16} className="text-white" />
//       </button>

//       {/* Right Arrow */}
//       <button
//         className="hidden lg:block absolute right-0 top-full transform -translate-y-1/2 p-1 mt-4 bg-primary_green rounded-full"
//         onClick={() => scroll("right")}
//       >
//         <IconArrowRight size={16} className="text-white" />
//       </button>

//       <div
//         ref={scrollRef}
//         className="flex overflow-x-auto pb-4 gap-4 no-scrollbar scroll-smooth"
//       >
//         {requests.length > 0 ? (
//           requests.map((request, index) => (
//             <SendRequestCard
//               key={index}
//               photo={request.photo}
//               name={request.name}
//               day={request.day}
//               month={request.month}
//               year={request.year}
//               location={request.location}
//               job={request.job}
//               height={request.height}
//               weight={request.weight}
//               race={request.race}
//               caste={request.caste}
//               religion={request.religion}
//               education={request.education}
//               income={request.income}
//               marriage={request.marriage}
//               alcohol={request.alcohol}
//               brothers={request.brothers}
//               sisters={request.sisters}
//               gender={request.gender}
//               email={request.email}
//               status={status}
//               mobile={request.mobile}
//             />
//           ))
//         ) : (
//           <p className="text-gray-500">{t("No requests available")}</p>
//         )}
//       </div>
//     </div>
//   );
// };

// export default SendRequestSection;
import React from "react";

const SendRequestSection = () => {
  return <div>SendRequestSection</div>;
};

export default SendRequestSection;
