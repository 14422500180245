import whiteLogo from "./text-white-logo.png";
import blackLogo from "./text-black-logo.png";
import bnLogo from "./all-black-logo.png";
import imagePicker from "./image-picker.png";
import menAvatar from "./men_avatar.jpg";
import womenAvatar from "./women_avatar.jpg";
import noAccept from "./no_accept.svg";
import noProposal from "./no_proposal.svg";
import noReceive from "./no_received.svg";
import receivedPro from "./received.svg";
import sithuminaBg from "./sithumina_bg.png";
import sithuminaBgGreen from "./sithumina_bg_green.png";
import defaultAvatar from "./defualt_avatar.jpg";

export {
  whiteLogo,
  blackLogo,
  bnLogo,
  imagePicker,
  menAvatar,
  womenAvatar,
  noAccept,
  noProposal,
  noReceive,
  receivedPro,
  sithuminaBg,
  sithuminaBgGreen,
  defaultAvatar,
};
