import Homepage from "./home/Homepage";
import Login from "./login/Login";
import Register from "./register/Register";
import NICnumber from "./register/NICnumber";
import PersonalDetails from "./register/PersonalDetails";
import Profile from "./profile/Profile";
import Proposals from "./proposals/Proposals";
import SendRequests from "./send_requests/SendRequests";
import ReceiveRequests from "./receive_requests/ReceiveRequests";

export {
  Homepage,
  Login,
  Register,
  NICnumber,
  PersonalDetails,
  Profile,
  Proposals,
  SendRequests,
  ReceiveRequests,
};
